// consumer_evection_expenseOrderListExcel
const __request = require(`./__request/__request_contentType_json`);
const consumer_evection_expenseOrderListExcel = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/evection/expenseOrderListExcel',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_evection_expenseOrderListExcel;
