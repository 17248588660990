import moment from 'moment'

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'

import consumer_evection_expenseOrderList from '@/lib/data-service/haolv-default/consumer_evection_expenseOrderList';// 获取出差单列表
import consumer_web_company_selectOwnerProjects
    from '@/lib/data-service/haolv-default/consumer_web_company_selectOwnerProjects';// 获取项目归属列表
import consumer_web_costCenter_allList from "@/lib/data-service/haolv-default/consumer_web_costCenter_allList";// 获取成本中心
import consumer_evection_expenseOrderListExcel
    from '@/lib/data-service/haolv-default/consumer_evection_expenseOrderListExcel';
import consumer_evection_expenseOrderPdf
    from '@/lib/data-service/haolv-default/consumer_evection_expenseOrderPdf'
import consumer_orderNo_center_userAllowBookingDepts
    from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'
import consumer_evection_changeBelongProject
    from '@/lib/data-service/haolv-default/consumer_evection_changeBelongProject'
import baseUrl from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";

export default {
    data() {
        return {
            companyId: '',
            loading: false,
            searchForm: {
                evectionNo: '',//出差单号
                psgName: '',//出行人
                startDateTime: '',//出差日期-开始
                endDateTime: '',//出差日期-结束
                deptIds: [],//部门id
                deptName: '',//部门名称
                feeAffiliationName: '',//成本中心
                businessTypeStr: '',//业务类型
                belongingProject: '',//归属项目
                internalOrderNo: '',//内部订单号
                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            },
            travelTime: [],//出差日期
            costCenterList: [],//成本中心
            ownerProjectList: [],//归属项目列表
            deptList: [],
            totalCount: 0,   //总条数
            tripList: [],
            orderNoList: [],//选中的订单
            bizOrderNoList: [],//选中的退改订单号
            orderNos: '',//需要变更归属项目的订单号们
            belongingProject: '',//订单们需要变更的归属项目
            canDownload: true,
            dialogOwnerProjectVisible: false,
            checkbox1: Math.random(),
            checkbox2: Math.random(),
            changeProjectForm: {
                orderNos: '',
                belongingProject: ''
            },
            changeRules: {
                orderNos: [{required: true, message: "请输入订单号", trigger: "blur"}],
                belongingProject: [{required: true, message: "请选择归属项目", trigger: ['change', 'blur']}],
            },
            tableKey: Math.random(),
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.getUserInfo();
        this.getDeptList();//获取部门列表
        this.getCostCenter();//获取成本中心
        this.getOwnerProjectList();//获取归属项目列表
        this.getTripList()//获取出差单列表
    },
    watch: {
        travelTime: {
            handler(val) {
                if (val && val.length) {
                    this.searchForm.startDateTime = moment(val[0]).format('YYYY-MM-DD 00:00:00')
                    this.searchForm.endDateTime = moment(val[1]).format('YYYY-MM-DD 23:59:59')
                } else {
                    this.searchForm.startDateTime = ''
                    this.searchForm.endDateTime = ''
                }
            }
        },
    },
    methods: {
        getUserInfo() {
            get_user_info().then(res=>{
                this.companyId = res.datas.companyId;
            })
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },

        //获取成本中心
        async getCostCenter() {
            let center = (datas) => {
                let list = datas.map((item) => {
                    let obj = {deptId: item.id, deptName: item.centerName};
                    return obj;
                })
                return list
            }
            let res = await consumer_web_costCenter_allList();
            this.costCenterList = res.datas.map((item) => {
                let obj = {deptId: item.id, deptName: item.centerName};
                return obj;
            })
        },

        //获取项目归属列表
        async getOwnerProjectList() {
            let res = await consumer_web_company_selectOwnerProjects();
            this.ownerProjectList = res.datas;
        },

        // 获取订单列表
        getTripList() {
            this.loading = true
            consumer_evection_expenseOrderList(this.searchForm).then(res => {
                let tripList = res.datas.list
                let businessTypeName = '';
                tripList.forEach(value => {
                    if (value.tusExpenseInfoList != null && value.tusExpenseInfoList.length > 0) {
                        value.tusExpenseInfoList.forEach(journey => {
                            journey.evectionNo = value.evectionNo;
                            switch (journey.businessType) {
                                case 1:
                                    businessTypeName = '飞机';
                                    break;
                                case 2:
                                    businessTypeName = '火车票';
                                    break;
                                case 3:
                                    businessTypeName = '酒店';
                                    break;
                            }
                            journey.businessTypeName = businessTypeName;
                        })
                    }
                })
                this.tripList = tripList;
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.tripList = [];
                this.totalCount = 0
            }).finally(() => {
                this.tableKey = Math.random();
                this.loading = false
            })
        },

        // 搜索
        onSearch() {
            this.searchForm.currentPage = 1
            this.getTripList()
        },

        // 重置
        onReset() {
            this.searchForm = {
                evectionNo: '',//出差单号
                psgName: '',//出行人
                startDateTime: '',//出差日期-开始
                endDateTime: '',//出差日期-结束
                deptIds: [],//部门id
                deptName: '',//部门名称
                feeAffiliationName: '',//成本中心
                businessTypeStr: '',//业务类型
                belongingProject: '',//归属项目
                internalOrderNo: '',//内部订单号
                currentPage: 1,   //当前页码
                pageSize: 10,
            }
            this.travelTime = []//出差日期
                this.onSearch()
        },

        //选择部门
        changeDept() {
            let deptId = [];
            this.searchForm.deptIds.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptsId = deptId;
        },

        //选择出差单
        handleSelectionChange(isSelected, trip) {
            trip.isSelected = isSelected;
            if (trip.tusExpenseInfoList != null && trip.tusExpenseInfoList.length > 0) {
                trip.tusExpenseInfoList.forEach(journey => {
                    journey.isSelected = isSelected;
                });
            }
            this.checkbox1 = Math.random();
            this.checkbox2 = Math.random();
        },

        //选择行程
        handleJourneySelectionChange(isSelected, journey) {
            journey.isSelected = isSelected;
            this.tripList.forEach(trip => {
                if (trip.evectionNo === journey.evectionNo) {
                    let selectedNum = 0;
                    trip.tusExpenseInfoList.forEach(journey => {
                        if (journey.isSelected) {
                            selectedNum += 1;
                        }
                    });
                    if (selectedNum === trip.tusExpenseInfoList.length) {
                        trip.isSelected = true;
                    } else {
                        trip.isSelected = false;
                    }
                }
            });
            this.checkbox1 = Math.random();
            this.checkbox2 = Math.random();
        },

        //获取选中的订单号和退改订单号
        getOrderList() {
            this.orderNoList = [];
            this.bizOrderNoList = [];
            this.tripList.forEach(trip => {
                if (trip.tusExpenseInfoList !== null && trip.tusExpenseInfoList.length > 0) {
                    trip.tusExpenseInfoList.forEach(journey => {
                        if (journey.isSelected) {
                            this.orderNoList.push(journey.orderNo);
                            this.bizOrderNoList.push(journey.bizOrderNo);
                        }
                    });
                }
            })
            this.changeProjectForm.orderNos = this.orderNoList.join(',');
        },

        //导出明细
        downloadEvectionExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false
            consumer_evection_expenseOrderListExcel(this.searchForm).then(res => {
                let url = res.datas.expenseOrdersUrl
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    })
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },

        //显示变更归属项目弹框
        openChangeProjectPopup() {
            this.getOrderList();
            if (this.changeProjectForm.orderNos === '') {
                this.$message({
                    message: '请先选择需要更改归属项目的订单',
                    type: 'warning'
                });
                return;
            }
            this.dialogOwnerProjectVisible = true;
            this.$nextTick(() => {
                this.$refs.changeProjectForm.clearValidate();
            })
        },

        //取消变更归属项目
        cancelChangeProject() {
            this.dialogOwnerProjectVisible = false;
        },

        //变更归属项目
        changeOwnerProject() {
            this.$refs.changeProjectForm.validate((valid) => {
                if (valid) {
                    let {
                        belongingProject,
                    } = this.changeProjectForm;
                    let project = this.ownerProjectList.find(item => {
                        return item.projectCode === belongingProject;
                    })
                    let params = {
                        bizOrderNo: this.bizOrderNoList,
                        belongCodeProject: belongingProject,
                        belongNameProject: project.projectName
                    }
                    consumer_evection_changeBelongProject(params).then(res => {
                        this.$message({
                            message: '更改归属项目成功',
                            type: 'success'
                        });
                        this.getTripList();
                        this.dialogOwnerProjectVisible = false;
                    });
                }
            })
        },

        //下载报销凭证
        downloadReimbursementVoucher() {
            this.getOrderList();
            if (!this.canDownload) {
                return
            }
            this.canDownload = false
            const environment = global.appConfig.environment;
            const urlPrefix = baseUrl({
                environment: environment,
                appKey: "haolv-default"
            });
            this.searchForm.companyId = this.companyId;
            let html = JSON.stringify(this.searchForm);
            html = encodeURI(html);
            /*Object.keys(this.searchForm).forEach((keys)=>{
                html += `${keys}=${this.searchForm[keys]}&`
            })*/
            console.log(html);
            this.canDownload = true;
            window.open(urlPrefix + '/consumer/evection/expenseOrderPdf?param=' + html);

            /*consumer_evection_expenseOrderPdf(this.searchForm).then(res => {
                let url = res.datas.expenseOrdersUrl
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    })
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })*/
        }
    }
}
