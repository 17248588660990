// consumer_evection_changeBelongProject
const __request = require(`./__request/__request_contentType_json`);
const consumer_evection_changeBelongProject = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/evection/changeBelongProject',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_evection_changeBelongProject;
